<footer class="footer">
    <div class="mobile">
        <div class="footer__links">
            <a target="_blank" href="https://pxc.io/de/impressum">Impressum</a>
            <a target="_blank" href="https://pxc.io/de/datenschutz">Datenschutz</a>
        </div>
    </div>

    <div class="desktop">
        <div class="footer__desktop">
            <div class="footer__links">
                <a target="_blank" href="https://pxc.io/de/impressum">Impressum</a>
                <a target="_blank" href="https://pxc.io/de/datenschutz">Datenschutz</a>
            </div>
        </div>
    </div>
</footer>