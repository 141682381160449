<footer class="footer">
    <div class="mobile">
        <div class="footer__links">
            <a target="_blank" href="https://pxc.io/en/impressum">Impress</a>
            <a target="_blank" href="https://pxc.io/en/datenschutz">Data Policy</a>
        </div>
    </div>

    <div class="desktop">
        <div class="footer__desktop">
            <div class="footer__links">
                <a target="_blank" href="https://pxc.io/en/impressum">Impress</a>
                <a target="_blank" href="https://pxc.io/en/datenschutz">Data Policy</a>
            </div>
        </div>
    </div>
</footer>